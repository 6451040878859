<template>
  <div class="">
    
    <HeaderVue heading="Bullet Description" class="mb-3">
      <template v-slot:buttons>
        <div class="d-flex align-items-between">
    <!-- <AddTagModal></AddTagModal> -->
          <AddCategory ref="addTagModal" :categoryTitle="{singular:'description', plural:'descriptions'}" @submit="saveTags($event)" :isLoading="isLoadAddTags" ></AddCategory>
         
       
        </div>
      </template>
    </HeaderVue>

    <div class="card border-radius">
      <div class="card-body">
        <div class="">
          <FilterBox
            :search="true"
            v-model.trim="titleInput"
            placeholder="Search Bullet Description..."></FilterBox>
                        <div class="loader d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center" v-if="isLoading">
                <b-spinner small style="color: #4d1b7e"></b-spinner>
                <span class="ml-2">Loading Bullet Description</span>
              </div>
            </div>
          <div class="skeleton" v-if="isLoadingFirstTime">
            <b-skeleton-table
              class="py-3"
              :rows="5"
              :columns="4"
              :table-props="{
                bordered: true,
                striped: true,
              }"></b-skeleton-table>
          </div>
          <div class="data-table"  v-else-if="items.length>0">
            <b-table
              head-class="text-center"
              class="leadTable"
              :stickyColumn="true"
              hover
              :fields="fields"
              :items="items">
                <template #head(checkbox)>
              <b-form-checkbox
                v-model="selectAllQuizellBulletDescription"
                size="lg"
                @input="toggleQuizellTags()"
                class="checkbox"></b-form-checkbox>
            </template>
               <template #cell(checkbox)="data">
              <b-form-checkbox
                v-model="selectedQuizellBulletDescription"
                :value="data.item.id"
                size="lg"
                class="checkbox"></b-form-checkbox>
            </template>
               <template #cell(title)="data">
                <div class="d-flex align-items-center">
                 
                  <span class="text-hover-underline"  @click="$refs.EditDescriptionModal.open({title:data.item.title,image:''})">{{ data.item.title }}</span>
                </div>
              </template>
  <template #cell(actions)="data">
    <button  class="btn" @click="()=>{
      editDescriptionId=data.item.id;
      $refs.EditDescriptionModal.open({title:data.item.title,image:''})
    }"><svg  viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="pencil square" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-pencil-square b-icon bi"><g ><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path><path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"></path></g></svg></button>
                <!-- <b-dropdown  dropright class="" id="collection-detail">
                  <template slot="button-content">
                    <div class="p-2">
                      <i class="text-dark fa-solid fa-ellipsis-vertical"></i>
                    </div>
                  </template>
                  <b-dropdown-item class="topbarLink d-flex align-items-center" @click.stop="$refs.viewProducts.getProducts(data.item.id)">
                    View Products</b-dropdown-item
                  >
                  <b-dropdown-item class="topbarLink d-flex align-items-center">
                    Rest Product</b-dropdown-item
                  >
                </b-dropdown> -->
     </template>
            </b-table>
             </div>
             <template v-else>

<div class="d-flex justify-content-center align-items-center" style="height: 50vh;" >

<EmptyTable >
<template #title>
No bullet found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
            <QuizellPagination  :total-rows="totalRows" :currentPage="currentPage"
                  :per-page="perPage"    v-if="items.length" @update="updatePagination" @UpdatePerPage="updatePerPage"></QuizellPagination>
         
        </div>
      </div>
    </div>

    <DeleteFooterModal ref="deleteModal" :isLoading="isLoadingDeleteTag" @click="deleteSelectedTags()" :itemLength="selectedQuizellBulletDescription.length" name="bullet description"
    ></DeleteFooterModal>
    <ViewCategoryProducts ref="viewProducts" category="description"></ViewCategoryProducts>
          <AddCategory ref="EditDescriptionModal" :editMode="true" :categoryTitle="{singular:'description', plural:'description'}" @submit="saveTags($event,true)" :isLoading="isLoadAddTags" ></AddCategory>

  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";
// import Button from "../Layout/Button.vue";
// import ShopifyPagination from "../Layout/ShopifyPagination.vue";
import FilterBox from "../Layout/FilterBox.vue";
import HeaderVue from "../Layout/Header.vue";
import EmptyTable from "../Layout/EmptyTable.vue";
import axios from "axios";
// import AddTagModal from './modal/AddTag.vue';
import DeleteFooterModal from '../Layout/DeleteFooterModal.vue';
import ViewCategoryProducts from './ViewCategoryProducts.vue';
import AddCategory from "./modal/AddCategory.vue";
import QuizellPagination from "../Layout/QuizellPagination.vue";
export default {
  components: {
    FilterBox,
    HeaderVue,
    QuizellPagination,
    // Button,
   EmptyTable,
    // AddTagModal,
    DeleteFooterModal,
    ViewCategoryProducts,
    AddCategory
  },
  created(){
    const query = this.$route.query;
    this.titleInput = this.searchTagText = query.title || '';
    this.currentPage= parseInt(query.page) || 1;
    this.perPage= parseInt(query.perPage) || 10;
  },
  data() {
    return {
      titleInput:'',
      editDescriptionId:null,
      isLoadAddTags:false,
      selectAllQuizellBulletDescription:false,
      selectedQuizellBulletDescription:[],
      searchTagText:'',
        currentPage: 1,
      perPage: 10,
      totalRows: 10,
      fields: [
        {
          key: "checkbox",
          label: "",
          tdClass: "data-Table align-middle cursor-pointer",
           thClass:'bg-table-heading'
        },
        
        {
          key: "title",
          label: "Title",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass:'bg-table-heading'
        },
        
        {
          key: "actions",
          label: "Actions",
          tdClass: "align-middle data-Table d-flex justify-content-end cursor-pointer",
          thClass: "bg-table-heading text-right",
        },
      ],
   
      items: [],
      shopifyTagsFilterText:'',
      isLoadingFirstTime: true,

      isLoadingDeleteTag:false,
      isLoadingSync:false,
      isLoading: false,

    };
  },
  watch: {
     items:{
      handler(newValue){
        if(newValue.every(collection=>this.selectedQuizellBulletDescription.includes(collection))&&(!newValue))
        this.selectAllQuizellBulletDescription=true;
        else
         this.selectAllQuizellBulletDescription=false;
      },
      deep:true
   },
   titleInput(){
        this.handleTagSearchDebounced()
      },
      '$route.query.title'(newValue) {
      this.currentPage=1;
      this.titleInput=newValue;
      this.searchTagText=newValue
      
    },
    '$route.query.page'(newValue) {
      this.currentPage= newValue ? parseInt(newValue) : 1;
    },
    '$route.query.perPage'(newValue) {
      this.perPage= newValue ? parseInt(newValue) : 10;
    },
    },
  computed: {
    
    ...mapGetters(["getNavbarData"]),
  },

  methods: {
    updatePerPage(e){
      this.currentPage=1;
      this.perPage=e;
      this.applyFilters();
    },
    updatePagination(e){
      this.currentPage=e;
      this.applyFilters();
    },
    applyFilters() {
      this.searchTagText=''
      const queryParams = {};
      if (this.titleInput) {

        queryParams.title = this.titleInput;
         this.searchTagText = this.titleInput;
      }
      queryParams.perPage=this.perPage;
      queryParams.page=this.currentPage;
      this.$router.replace({ query: queryParams });
      this.getDescription()
    },
       async saveTags(data,editMode=false){
       let path = editMode?'/products/bullets':'/products/bullets'
       let method='post'
       if(editMode) 
       {
        method='put'
        path = '/products/bullets/'+ this.editDescriptionId
       }
      this.isLoadAddTags=true
     await axios[method](path,data).then(()=>{
        this.$toasted.show("Bullet description saved successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          if(editMode)
          this.$refs.EditDescriptionModal.close();
        else
          this.$refs.addTagModal.close();
          this.getDescription();
      }).catch((error)=>{
        let {title} = error.response.data.errors
        if(title){
          this.$toasted.show(title, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        } else{
          this.$toasted.show("Error occurred.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      })
      this.isLoadAddTags=false
    },
    toggleQuizellTags(){
        if(this.selectAllQuizellBulletDescription)
       this.selectedQuizellBulletDescription =  [...new Set([...this.selectedQuizellBulletDescription, ...this.items.map(tag=>tag.id)])];
       else 
        this.selectedQuizellBulletDescription = []
    },
    async deleteSelectedTags(){
      this.isLoadingDeleteTag=true
 await axios
        .post("/products/bullets/bulk-delete",{ids: this.selectedQuizellBulletDescription})
        .then(() => {
          this.$refs.deleteModal.close();
          this.currentPage>1&&this.selectedQuizellBulletDescription==this.items?this.updatePagination(this.currentPage):this.getDescription()
          this.selectedQuizellBulletDescription=[];
         
    
          this.$toasted.show("Bullet description deleted successfully", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        })
        .catch((e) => {
           this.$toasted.show("Error occurred while deleting bullet description", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
          console.log(e);
        });
        this.isLoadingDeleteTag=false
    },
     PaginationData() {
      this.getDescription();
    },
    async getDescription() {
      this.isLoading = true;
      await axios
        .get('/products/bullets/list',{params:{
          page: this.currentPage,
          title:this.searchTagText
        }})
        .then((response) => {
          this.items=response.data.data.data;
           this.totalRows = response.data.data.total;
        
          
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoadingFirstTime = false;
      this.isLoading = false;
     
    },
   
    handleSearchDebounced: debounce(function () {
      this.getTagsFromShopify();
    }, 500), // 500ms debounce time, adjust as needed
     handleTagSearchDebounced: debounce(function () {
      this.applyFilters();
    }, 500),
  },
  mounted(){
   this.getDescription()
  }
};
</script>
<style scoped>
.shopify-btn:active {
  background-color: #002e25;
  color: #ffffff;
  border-color: #002e25;
}
.shopify-btn {
  appearance: none;
  cursor: pointer;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #008060;
  color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

font-size: 14px;

  transition: 150ms ease;
  transition-property: background-color, border-color;
  display: inline-block;

  border-radius: 6px;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color) !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--secondary-color);
}
.leadTable >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.leadTable >>> thead tr {
  border-radius: 10px;
}
.leadTable >>> thead th {
  border: none;
}
.loader {
  min-height: 40px;
}

.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button
{
  color: white;
  background-color: #4d1b7e!important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover
{
  color: white;
  background-color: #6b4690!important;
  border-color: #4d1b7e;
}
#collection-detail >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
#collection-detail >>> button::after {
  display: none;
}
#collection-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#collection-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#collection-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: #4d1b7e;
}
</style>
